<template>
  <div>
    <header class="mb-12">
      <div class="header-new">
        <div class="img-logo">
          <img
            src="/L1.png"
            height="80%"
          >
        </div>
        <!-- https://f.pco.bet/logo/f9ba71f6-39e7-6224-36de-a2a15d016177.gif -->
        <div class="balance-txt">
          <div class="balance-txt__text d-flex align-items-center">
            <span class="ml-2">
              {{ Commas(MainWallet) }}
              <img
                src="/icons/baht.svg"
                width="25px"
              >
            </span>
          </div>
          <div
            class="nav-side cursor-pointer d-flex align-items-center"
            @click="showModal"
          >
            <i class="fas fa-bars" />
          </div>

        </div>

      </div>
    </header>

    <b-modal
      ref="my-modal"
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <div>
        <div class="hd-h2 text-center ">
          <h3 class="text-gold">
            เมนูหลัก
          </h3>
        </div>

        <div class="-modal-profile-mobile">
          <div class="text-center">
            <img
              src="/L1.png"
              class="p-2"
            ></div>
          <!-- <div class="-balance-containers ng-star-inserted text-center">
            <div class="-text-username">
              {{ UserData.name }} {{ UserData.surname }}
            </div>
            <div class="-user-balance js-user-balance f-sm-6 f-7">
              <div class="-inner-box-wrapper d-flex align-content-center justify-content-center">

                <span
                  id="customer-balance"
                  class="text-white font-weight-bold"
                >
                  {{ Commas(MainWallet) }}
                  <img
                    src="/icons/baht.svg"
                    alt="customer image"
                    width="25"
                  >
                </span>
              </div>
            </div>
          </div> -->

          <ul class="navbar-navs">
            <li
              v-if="UserData && UserData.usertype
                == 'member' && UserData.userID != 10052"
              class="nav-item ng-star-inserted"
            >
              <router-link :to="{ name: 'tanghuay-home' }">
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/home.png"
                    alt="ic-menu-user"
                    class="-icon-image"
                    height="35"
                  ><span
                    class="-text-menu"
                  > หน้าหลัก </span>
                </button>
              </router-link>
            </li>

            <li
              v-if="UserData && UserData.usertype
                == 'member' && UserData.userID != 10052"
              class="nav-item ng-star-inserted"
            >
              <router-link :to="{ name: 'tanghuay-deposit' }">
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/ic-mobile-right-1.webp"
                    alt="ic-menu-user"
                    class="-icon-image"
                    height="35"
                  ><span class="-text-menu"> เติมเงิน </span>
                </button>
              </router-link>
            </li>

            <li
              v-if="UserData && UserData.usertype
                == 'member' && UserData.userID != 10052"
              class="nav-item ng-star-inserted"
            >
              <router-link :to="{ name: 'tanghuay-withdraw' }">
                <button
                  type="button"
                  class="nav-links btn-gold-2"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/download.webp"
                    alt="ic-menu-user"
                    class="-icon-image"
                    height="35"
                  ><span
                    class="-text-menu"
                  > ถอนเงิน </span>
                </button>
              </router-link>
            </li>

            <li class="nav-item ng-star-inserted">
              <router-link :to="{ name: 'tanghuay-round' }">
                <button
                  type="button"
                  class="nav-links btn-gold-2"
                  tabindex="0"
                  @click="hideModal"
                >
                  <img
                    src="@/assets/newImg/we.webp"
                    alt="ic-menu-user"
                    class="-icon-image"
                    height="35"
                  ><span
                    class="-text-menu"
                  > แทงหวย </span>
                </button>
              </router-link>
            </li>

            <li class="nav-item ng-star-inserted">
              <router-link :to="{ name: 'tanghuay-list' }">
                <button
                  type="button"
                  class="nav-links btn-gold-2"
                  tabindex="0"
                  @click="hideModal"
                >
                  <img
                    src="@/assets/newImg/doc.png"
                    alt="ic-menu-user"
                    class="-icon-image"
                    height="35"
                  >
                  <span class="-text-menu"> โพยของฉัน </span>
                </button>
              </router-link>
            </li>

            <li class="nav-item ng-star-inserted">
              <button
                v-if="PrintAuto"
                type="button"
                class="nav-links btn-gold-2"
                tabindex="0"
                @click="ChangPrintAuto"
              >
                <img
                  src="@/assets/newImg/print01.png"
                  alt="ic-menu-user"
                  class="-icon-image"
                  height="35"
                >
                <span class="-text-menu"> ปริ้นโพยอัตโนมัติ</span>
              </button>
              <button
                v-else
                type="button"
                class="nav-links btn-gold-2"
                tabindex="0"
                @click="ChangPrintAuto"
              >
                <img
                  src="@/assets/newImg/Unprint.png"
                  alt="ic-menu-user"
                  class="-icon-image"
                  height="35"
                >
                <span class="-text-menu"> ปิดปริ้นโพยอัตโนมัติ </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBToggle } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BModal,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      Interval: null,
      MainWallet: 0,
      // isScrolled: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    PrintAuto() {
      return this.$store.state.app.printListAuto
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  mounted() {
    this.ShowWallet()
    this.Interval = setInterval(() => {
      this.ShowWallet()
    }, 30000)
    // window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    // handleScroll() {
    //   this.isScrolled = window.scrollY > 1
    // },
    ChangPrintAuto() {
      this.$store.commit('app/UPDATE_PRINTLIST', !this.PrintAuto)
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    async ShowWallet() {
      try {
        const params = {
          UserToken: localStorage.getItem('UserToken'),
        }
        const { data: response } = await this.$http.get(
          '/seamless/wallet/show', { params },
        )
        if (response && response.success === true) {
          const Main = response.WalletData
          this.MainWallet = Main.balance
          this.$store.state.appConfig.UserData = { Balance: Main.balance, Discount: response.discount }
          if (Number(this.UserData.count_login) !== Number(response.count_login)) {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem('userData')
            localStorage.removeItem('UserToken')
            localStorage.removeItem('BreakToken')
            this.$ability.update(initialAbility)
            window.location.href = this.UserData.site
          }
        } else {
          this.MainWallet = 0
          this.$store.state.appConfig.UserData = { Balance: 0, Discount: 0 }
        }
      } catch (err) {
        console.log(err)
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    // async ShowWallet() {
    //   this.MainWallet = this.$store.state.appConfig.UserData.Balance
    //   this.discount = this.$store.state.appConfig.UserData.Discount
    // },
  },

}
</script>
